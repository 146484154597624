<template>
    <div class="process-progress">
        <div class="resume-upload-result">
            <h1>上传{{isPersonalCreate?'个人':'企业'}}简历</h1>
            <div class="h2 result-title">
                <span class="excel-file-name" v-if="excelFileName">【{{excelFileName}}】</span>
                <span class="result-count">
                    上传<span class="color-orange">{{isExcelUpload ? excelUploadTotal : tableData.length}}</span>份，解析中<span class="color-orange">{{count.doingCount}}</span>份，成功<span
                    class="color-orange">{{count.succeedCount}}</span>份，失败<span class="color-orange">{{count.failedCount}}</span>份
                </span>
                <span class="result-tips">
                    <em>Tips：</em>
                    上传完成后可在
                    <a class="text-operate-btn" href="/Headhunting/#/talentPool" target="_blank">Talentpool</a>
                    进行查看
                </span>
            </div>

            <div class="table-content">
                <el-table
                    :data="tableData"
                    style="width:100%"
                    v-loading="loading"
                >
                    <el-table-column
                        prop="fileName"
                        label="文件名"
                        width="310"
                        :show-overflow-tooltip="true"
                        v-if="!isExcelUpload"
                    >
                        <template slot-scope="scope">
                            <span class="file-name">
                                <span class="name">{{ scope.row._fileName }}</span><span class="type">{{ scope.row._fileType }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        class-name="real-name"
                        prop="realName"
                        label="姓名"
                        :width="isExcelUpload ? '200' : '122'"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <span v-if="!scope.row.realName">—</span>
                            <span
                                :class="scope.row.resumeId ? 'real-name-link' : ''"
                                v-else
                                @click="scope.row.resumeId ? goToCandidate(scope.row.resumeId) : ''"
                            >
                                {{scope.row.realName}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="result"
                        label="添加候选人结果"
                        :width="isExcelUpload ? '400' : '332'"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <span class="upload-status" v-if="scope.row.status==2||scope.row.status==4">
                                <font-icon class="lbd-icon__upload_status"
                                           href="#icon-resume_ic_success"></font-icon>
                                <span>成功</span>
                                <span v-if="scope.row.status==4">：个人库存在，已为你自动更新</span>
                            </span>
                            <span class="upload-status" v-else-if="scope.row.status==3 || scope.row.status == 5">
                                <font-icon class="lbd-icon__upload_status"
                                           href="#icon-resume_ic_default-copy"></font-icon>
                                <span class="color-red">失败</span>
                                <span>：{{scope.row.failedReaosn}}</span>
                            </span>
                            <span class="upload-status" v-else-if="scope.row.status==1">
                                <span class="icon-analyze"></span>
                                <span class="color-blue">解析中...</span>
                            </span>

                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        :width="isExcelUpload ? '' : '219'"
                    >
                        <template slot-scope="scope">
                            <div v-if="scope.row.isCanGrab" @click="handleFirm(scope.row, scope.$index)">
                                <span class="text-operate-btn">导入企业库</span>（抢占归属）
                            </div>

                            <div v-else-if="scope.row.status==3 && scope.row.failedType==1">
                                <span class="text-operate-btn" @click="handleFill(scope.row)">去补充</span>
                            </div>

                            <div v-else-if="(scope.row.status==3 && scope.row.failedType==2) || scope.row.status == 5">
                                <span class="text-operate-btn" @click="handleCompare(scope.row)">去对比</span>
                            </div>
                            <div v-else>
                                <span>—</span>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- :width="isExcelUpload ? '300' : ''" -->
                    <el-table-column
                        prop="uploadTime"
                        label="上传时间"
                        :width="300"
                    >

                    </el-table-column>
                </el-table>
            </div>
            <div class="layer-analyze" v-show="isExcelLoading">
                <div class="screen"></div>
                <div class="layer-body">
                    <div class="icon-analyze"></div>
                    <span class="excelupload-text">解析上传中，请勿关闭本页面…</span>
                    <span class="excelupload-count">
                        上传<span class="color-orange">{{excelUploadTotal}}</span>份，解析中<span class="color-orange">{{count.doingCount}}</span>份，成功<span
                        class="color-orange">{{count.succeedCount}}</span>份，失败<span class="color-orange">{{count.failedCount}}</span>份
                    </span>
                </div>
            </div>
        </div>
        <import-result-dialog
            ref="importResultDialog"
            :isTextContext="true"
            @import-then-compare="goToCompare"
        ></import-result-dialog>

        <div class="lbd-sign"></div>
    </div>
</template>

<script>
    import moment from 'moment';
    import talentPoolService from '@src/js/service/talentPoolService.js';
    import degreeData from '#/js/config/degreeData.json';
    import ImportResultDialog from '@src/component/common/dialog/importResultDialog.vue';
    let timer = null;
    export default {
        name: "ProcessProgress",
        components: {
            ImportResultDialog
        },
        data() {
            return {
                tableData: [],
                count:{
                    doingCount: 0,
                    succeedCount:0,
                    failedCount:0
                },
                isPersonalCreate:_getQueryString('type')=="0"?true:false,
                excelFileName: "",
                loading: false,
                isExcelLoading: false,
                excelUploadTotal: 0
            }
        },
        computed: {
            userId(){
                return this.$store.state.user.userInfo.id;
            },
            isCFuser (){

            },
            isExcelUpload() {
                return this.$route.query.uploadType == 0 ? true : false;
            }
        },
        mounted(){
            if (this.$route.params.id) {
                if(this.isExcelUpload && sessionStorage.getItem('excelUploadParams')) {
                    this.isExcelLoading = true;
                    this.excelUploadTotal = this.$route.params.total;
                    this.excelImport();
                    this.getRecodeList(this.$route.params.id);
                    let excelTimer = null,
                        noDataSeconds = 0,
                        uploadDoneNum = 0;
                    excelTimer = setInterval(() => {
                        this.getRecodeList(this.$route.params.id, () => {
                            if(this.tableData.length == this.excelUploadTotal){
                                this.isExcelLoading = false;
                                clearInterval(excelTimer);
                            }
                            if(this.tableData.length !== uploadDoneNum) {
                                uploadDoneNum = this.tableData.length;
                                noDataSeconds = 0;
                            } else {
                                noDataSeconds += 5000;
                                if(noDataSeconds == 300000) {
                                    this.isExcelLoading = false;
                                    clearInterval(excelTimer);
                                }
                            }
                        });
                    }, 5000);
                } else {
                    this.getRecodeList(this.$route.params.id);
                    timer = setInterval(() => {
                        this.getRecodeList(this.$route.params.id);
                        if(this.count.doingCount==0){
                            clearInterval(timer)
                        }
                    }, 2000);
                }
            }
        },
        methods: {
            excelImport() {
                let params = JSON.parse(sessionStorage.getItem('excelUploadParams'));
                this.excelFileName = params.FileName;
                _request({
                    method: 'POST',
                    url: '/My/Candidate/Excel/Import',
                    throwHttpError: true,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: params
                }).then(res => {}).catch(err => {
                    if(res.status && res.status != 504) {
                        shortTips('网络错误，请稍后重试');
                    }
                });
            },
            getRecodeList (recodeId, cd){
                let doingCount = 0;
                let succeedCount = 0;
                let failedCount = 0;
                _request({
                    method: 'GET',
                    url: `/My/Resume/MultipleUploadResults/${recodeId}`,
                }).then(res => {
                    if (res.resumeResults && !!res.resumeResults.length) {
                        res.resumeResults.map(item => {
                            item._fileName = item.fileName.slice(0 , item.fileName.lastIndexOf('.'));
                            item._fileType = item.fileName.slice(item.fileName.lastIndexOf('.'));
                            item.uploadTime = moment(item.uploadTime).format('YYYY-MM-DD HH:mm:ss');
                            switch (item.status) {
                                case 1:
                                    if(!this.isExcelUpload) {
                                        doingCount++;
                                    }
                                    break;
                                case 2:
                                case 4:
                                    succeedCount++;
                                    break;
                                case 3:
                                    failedCount++;
                                    break;
                                default:
                                    break;
                            }
                        });
                        this.tableData = res.resumeResults.reverse();
                        if(this.isExcelUpload) {
                            doingCount = this.excelUploadTotal - succeedCount - failedCount;
                            cd && cd();
                        }
                        this.count = {
                            doingCount: doingCount,
                            succeedCount: succeedCount,
                            failedCount: failedCount
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
            },
            //导入企业库 请求函数
            importToFirm(id) {
                let resumeIds = [];
                resumeIds.push(id);
                this.loading = true;
                return talentPoolService.importToFirm({
                    tbdResumeIds: resumeIds
                });
            },
            addToCompany(id, index) {
                this.$confirm('确定导入到企业人才库？', '提示', {
                    showClose: false,
                    cancelButtonText: '取消',
                    confirmButtonText: '确定'
                }).then(() => {
                    this.importToFirm(id).then((res) => {
                        this.loading = false;
                        if(res) {
                            this.$refs.importResultDialog.show(res);
                            if(res.failCount == 0) {
                                this.tableData[index].isCanGrab = false;
                            }
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });
                }).catch(() => {});
            },
            handleFill(item){
                //去补充进入编辑页面
                this.getResumeData(item.resultId,(resumeData)=>{
                    let fileName = "";
                    let resumeBasicInformation = {
                        "isExisted":false,
                        "existedId":null,
                        "realName": "",
                        "gender": 0,
                        "mobile": "",
                        "title": "",
                        "email": "",
                        "source": null,
                        "company": "",
                        "yearOfExperience": 0,
                        "province": "",
                        "city": null,
                        "content": "",
                        "extraInfo": null,
                        "jobHoppingOccasion": null,
                        "employerInfo": null,
                        "expectSalary": null,
                        "school": "",
                        "major": "",
                        "degree": "0",
                        "fileId": "",
                        "autoApprovelTrading": false,
                        "selfEvaluation": '',
                        "workExperiences": [],
                        "educationExperiences": [],
                        "projectExperiences": [],
                        "intentions": [],
                        "isPersonalCreate":true
                    };
                    Object.assign(resumeBasicInformation, resumeData);
                    if(!resumeBasicInformation.workExperiences || (resumeBasicInformation.workExperiences && resumeBasicInformation.workExperiences.length == 0)) {
                        resumeBasicInformation.workExperiences[0] =
                            {
                                "company": "",
                                "title": "",
                                "description": null,
                                "isLatestWorkExperience": true,
                                "location": 0,
                                "startDate": null,
                                "endDate": null,
                                "created": "",
                                "updated": "",
                                //"showPopover": false,
                                "locationArr": [],
                                "startDateError": false,
                                "endDateError": false,
                                "dateRange":[]
                            };
                        resumeBasicInformation.workExperiences[0].company = resumeData.company || "";
                        resumeBasicInformation.workExperiences[0].title =  resumeData.title || "";
                    }
                    if(!resumeBasicInformation.educationExperiences || (resumeBasicInformation.educationExperiences && resumeBasicInformation.educationExperiences.length == 0)) {
                        resumeBasicInformation.educationExperiences[0] =
                            {
                                "school": "",
                                "major": "",
                                "degree": null,
                                "startDate": null,
                                "endDate": null,
                                "created": "",
                                "updated": "",
                                //"showPopover": false,
                                "startDateError": false,
                                "endDateError": false,
                                "dateRange":[]
                            };
                        resumeBasicInformation.educationExperiences[0].school = resumeData.school || "";
                        resumeBasicInformation.educationExperiences[0].degree = resumeData.degree || null;
                    }
                    fileName = !this.isExcelUpload ? resumeData.fileName : this.excelFileName;
                    sessionStorage.setItem(encodeURIComponent(fileName), JSON.stringify(resumeBasicInformation));
                    // window.open(`/Headhunting/MyCompany.html?type=${_getQueryString('type')}&file=${fileName}#/resumeUpload/singleUpload`)
                    window.open(`/Headhunting/MyCompany.html?type=${_getQueryString('type')}&file=${fileName}#/resumeUpload/singleUpload`)
                });
            },
            handleFirm(item, index){
                //导入企业库抢占
                let resumeId = item.resumeId;
                this.addToCompany(resumeId, index);
                _tracker.track('ImportFirm', JSON.stringify({
                    resumeId: resumeId,
                    userId: this.userId
                }));
            },
            handleCompare(item){
                this.getResumeData(item.resultId,(resumeData)=>{
                    let fileName = "",
                        resumeId = "";
                    fileName = !this.isExcelUpload ? resumeData.fileName : this.excelFileName;
                    resumeId = !this.isExcelUpload ? (this.isPersonalCreate && item.resumeId ? item.resumeId : resumeData.resumeId) : item.resumeId;
                    sessionStorage.setItem(encodeURIComponent(fileName), JSON.stringify(resumeData));
                    _tracker.track('CompareResume', JSON.stringify({
                        resumeId: resumeId,
                        userId: this.userId
                    }));
                    // window.open('/My/Candidate/updatecheck?file=' + fileName + '&originalResumeId=' + resumeId);
                    // window.open('/Headhunting/MyCompany.html#/candidate/updatecheck?file=' + fileName + '&originalResumeId=' + resumeId);
                    if(this.isExcelUpload) {
                        window.open('/Headhunting/MyCompany.html#/candidate/updatecheck?file=' + fileName + '&originalResumeId=' + resumeId + '&isExcelUpload=1');
                        // window.open('/#/candidate/updatecheck?file=' + fileName + '&originalResumeId=' + resumeId + '&isExcelUpload=1');
                    }else {
                        window.open('/Headhunting/MyCompany.html#/candidate/updatecheck?file=' + fileName + '&originalResumeId=' + resumeId);
                        // window.open('/#/candidate/updatecheck?file=' + fileName + '&originalResumeId=' + resumeId);
                    }
                });

            },
            getResumeData(resultId,callback){
                let url = "";
                url = !this.isExcelUpload ? `/My/Resume/MultipleUploadResult/${resultId}/Data` : `/My/Candidate/Excel/Complete/${resultId}`
                _request({
                    method: 'GET',
                    url: url,
                }).then(res => {
                    callback && callback(res);
                }).catch(err => {
                    console.log(err);
                })
            },
            goToCandidate(id) {
                // window.open(`/Headhunting/MyCompany.html#/candidateDetail/${id}`, "_blank");
                window.open(`/Headhunting/MyCompany.html#/candidateDetail/${id}`, "_blank");
            },
            goToCompare(resume) {
                if(resume && resume.repeatResumeId) {
                    window.open(`/My/Candidate/updatecheck?resumeId=${resume.resumeId}&originalResumeId=${resume.repeatResumeId}`, '_blank');
                } else {
                    shortTips("系统异常，请稍后重试");
                }
            },
        },

        destroyed() {
            clearInterval(timer);
        }
    }
</script>

<style lang="scss">

    .process-progress {
        // height: calc(100vh - 52px);
        // background-color: #F5F5F5;
        // padding: 30px 0;
        // .w1200{
        //     height: 100%;
        //     display: flex;
        //     flex-direction: column;
        // }
        height: 100%;
        width: 1200px;
        margin: 20px auto;
    // padding-bottom: 20px;
        // height: 100%;
        overflow-y: auto;
        padding: 20px;

        scrollbar-width: none;  
        &::-webkit-scrollbar {
            display: none;
        }
        .resume-upload-result {
            height: 100%;
            background-color: #fff;
            border-radius: 8px;
            .color-orange {
                color: #FC7859;
            }

            .color-primary {
                color: $primary;
            }

            .color-red {
                color: #FF6564;
            }
            .color-blue{
                color: #4A90E2;
            }

            h1 {
                padding: 20px 20px 0;
                margin: 0;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
            }

            .h2 {
                padding: 20px 0;
                font-size: 14px;
                .excel-file-name {
                    margin-right: 10px;
                }
            }

            .table-content {
                padding: 20px;
                flex: 1;
                //height: 490px;
                background-color: #fff;
                overflow-y: auto;
            }

            .el-table {

                &:before {
                    display: none;
                }

                th {
                    padding: 0 0 0 30px;
                    height: 40px;
                    line-height: 40px;
                    background-color: #EEE;
                    border-color: #CCC;
                    color: #666;
                }

                .cell {
                    line-height: 20px;
                    padding-left: 0;
                }

                td {
                    padding: 10px 0;
                    border-top: 1px solid transparent;
                    border-right: 1px solid #EEE;

                    .cell{
                        padding: 0 30px !important;
                    }

                    &:first-child {
                        border-left: 1px solid transparent;
                    }
                    &.real-name {
                        .real-name-link:hover {
                            color: $primary;
                            cursor: pointer;
                        }
                    }
                }

                &.el-table--enable-row-hover .el-table__body tr:hover {
                    & > td {
                        border-top-color: $primary;
                        border-bottom-color: $primary;
                        background-color: #F8F8F8;

                        &:first-child {
                            border-left-color: $primary;
                        }

                        &:last-child {
                            border-right-color: $primary;
                        }
                    }
                }
            }

            .el-table--border {
                border-right: none;
                border-bottom: none;
            }

            .file-name {
                display: flex;
                max-width: 250px;

                .name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .upload-status {
                line-height: 19px;
                vertical-align: middle;
            }

            .lbd-icon__upload_status {
                margin-top: -3px;
                margin-right: 12px;
                width: 16px;
                height: 16px;
            }
            .icon-analyze {
                display: inline-block;
                width: 20px;
                height: 20px;
                background-image: url("~@src/assets/images/upload/item-analyze.gif");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                vertical-align: middle;
                margin-right: 12px;
            }
            .font-icon{
                margin-right: 12px;
            }
            .result-title{
                display: flex;
                justify-content: center;
                padding: 0 20px;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #eee;
                .result-count{
                    flex: 1;
                }
                .result-tips{
                    font-size: 12px;
                    color: #999999;
                    >em{
                        color: $primary;
                    }
                    > a {
                        font-size: 12px;
                    }
                }
            }
            .layer-analyze {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9999;

                .screen {
                    width: 100%;
                    height: 100%;
                    opacity: 0.4;
                    background: #000000;
                }

                .layer-body {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    line-height: 19px;
                    text-align: center;
                    color: #fff;
                    width: 500px;
                    height: 250px;
                    background-color: #fff;
                    color: #666;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    padding: 40px 0 50px;
                    .icon-analyze {
                        margin-bottom: 20px;
                        width: 55px;
                        height: 55px;
                        background-image: url("~@src/assets/images/upload/layer-analyze.gif");
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    .excelupload-text{
                        color: #F5A623;
                    }
                    .excelupload-count {
                        margin-top: 25px;
                    }
                }
            }
            .hover-highlight{
                cursor: pointer;
                &:hover{
                    color: $primary;
                }
            }
        }

        .lbd-sign {
            margin: 20px 0 0;
            &:after {
                content: '© 2023 liebide.com';
                margin-left: 50%;
                text-align: center;
                font-size: 14px;
                color: #999;
            }
        }
    }
</style>
